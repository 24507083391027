

export const Commonvariable = ({
    //BASE_API_URL:'http://159.65.55.237:9097/api/',
    //BASE_API_URL: 'https://172.16.0.165:8099/api/',
    //BASE_API_URL: 'http://172.16.0.216:4757/api/',
   // BASE_API_URL: 'http://localhost:44379/api/',//Local pc
   //BASE_API_URL: 'https://hr.luxshtech.com:7091/api/',//Test server
    BASE_API_URL: 'https://bnshr.bnsgroup.co.uk:7091/api/',//Live UK HR
   // BASE_API_URL:"https://localhost:44379/api/" ,// Chirag

    //... more of your variables
    DuplicationMsg: "Data already available",
    EmpAddMsg: "Thank You for Signing up, you will get notified after Signup confirmation, Regards!",
    SaveMsg: "Record saved successfully !",
    UpdateMsg: "Record updated successfully !",
    DeleteMsg: "Record deleted successfully !",
    InactiveMsg: "Record is Inactivate Successfully !",
    User_Id: localStorage.getItem('currentHRUser') != null ? JSON.parse(localStorage.getItem('currentHRUser'))["data"] != undefined ? JSON.parse(localStorage.getItem('currentHRUser'))["data"].user_Id : 0 : 0,
    Company_Id: localStorage.getItem('currentHRUser') != null ? JSON.parse(localStorage.getItem('currentHRUser'))["data"] != undefined ? JSON.parse(localStorage.getItem('currentHRUser'))["data"].company_Id : 0 : 0,
    emp_Id: localStorage.getItem('currentHRUser') != null ? JSON.parse(localStorage.getItem('currentHRUser'))["data"] != undefined
        && JSON.parse(localStorage.getItem('currentHRUser'))["data"] != null ? JSON.parse(localStorage.getItem('currentHRUser'))["data"].person_Id : 0 : 0,
    hrEmpId: localStorage.getItem('HR_Emp_Id') != null ? localStorage.getItem('HR_Emp_Id') : 0,
    onboarding_Id: localStorage.getItem('currentHRUser') != null ? JSON.parse(localStorage.getItem('currentHRUser'))["data"] != undefined ? JSON.parse(localStorage.getItem('currentHRUser'))["data"].onboarding_Id : 0 : 0,
    UserRole: localStorage.getItem('currentHRUser') != null ? JSON.parse(localStorage.getItem("currentHRUser"))["data"]["role"] : "",
    role_Id: localStorage.getItem('currentHRUser') != null ? JSON.parse(localStorage.getItem("currentHRUser"))["data"]["role_Id"] : "",
    Company: "company",
    CompanyDetail: "CompanyDetail",
    CompanyContact: "Company_Contact",
    Zone: "zone",
    Employee: "Employee",
    EmployeeDetail: "EmployeeDetail",
    Site: "site",
    Department: "department",
    Shift: "Shift",
    Contract: "Contract",
    Probation: "Probation",
    Document: "Document",
    User: "User",
    JobDescription: "job_Description",
    Designation: "designation",
    EmpBasicInfo: "Employee_BasicInfo",
    EmpAddress: "Employee_Address",
    EmpContact: "Employee_Contact",
    EmpEmergency: "Employee_Emergency",
    EmpBank: "Employee_Bank",
    EmpRef: "Employee_Reference",
    EmpRefrence:"Employee_Refrence",
    EmpProbation: "Employee_Probation",
    EmpContract: "Employee_Contract",
    EmpRightWork: "Employee_RightToWork",
    EmpSalary: "Employee_Salary",
    EmpDocument: "Employee_Document",
    Payroll: "PayrollService",
    EmployeeResignation: "Employee_Resignation",
    Hr_Policies_Document: "Hr_Policies_Document",
    Relationship: "Relationship",
    Roles: "Roles",
    User_Role: "User_Role",
    AuditLog: "AuditLog",
    Candidate: "Onboarding",
    Vacancy: "Vacancy",
    OnboardingDetail: "OnboardingDetail",
    Onboarding_BasicInfo: "Onboarding_BasicInfo",
    Onboarding_Address: "Onboarding_Address",
    Onboarding_Contact: "Onboarding_Contact",
    Onboarding_Emergency: "Onboarding_Emergency",
    Onboarding_Reference: "Onboarding_Reference",
    Onboarding_Bank: "Onboarding_Bank",
    Onboarding_Probation: "Onboarding_Probation",
    Onboarding_Contract: "Onboarding_Contract",
    Onboarding_RightToWork: "Onboarding_RightToWork",
    Onboarding_Salary: "Onboarding_Salary",
    Person_Equality:"Person_Equality",
    Onboarding_Document: "Onboarding_Document",
    Onboarding_Status: "Onboarding_Status",
    RightToWork_Category: "RightToWork_Category",
    RTWVisaStatus:"RTWVisaStatus",
    Asset: "Asset",
    Personal_Allowance: "Personal_Allowance",
    Raf_Approval: "Raf_Approval",
    VacancyService: "VacancyService",
    Candidate_Note: "Candidate_Note",
    VacancyRuleService: "Vacancy_RuleService",
    Vacancy_Rule: "Vacancy_Rule",
    Vacancy_Approval: "Vacancy_Approval",
    Publisher: "Publisher",
    Vacancy_Publish: "Vacancy_Publish",
    Vacancy_Rule_Detail: "Vacancy_Rule_Detail",
    CandidateService: "CandidateService",
    Candidate_Document: "Candidate_Document",
    Ethinicity: "Ethinicity",
    Budget: "Budget",
    Marital_Status: "Marital_Status",
    Nationality: "Nationality",
    Gender: "Gender",
    Currencies: "Currencies",
    Languages: "Languages",
    Country: "Country",
    ReasonForLeave: "ReasonForLeave",
    Person: "Person",
    PersonService: "PersonService",
    Person_Address: "Person_Address",
    Person_Contact: "Person_Contact",
    Person_Emergency: "Person_Emergency",
    Person_Reference: "Person_Reference",
    JobSeeker: "Candidate",
    Candidate_Vacancy_Link: "Candidate_Vacancy_Link",
    Candidate_Interviewer: "Candidate_Interviewer",
    Menu: "Menu",
    Module: "Module",
    Vacancy_History: "Vacancy_History",
    //Attendance_Rule:"Attendance_Rule",
    Holiday_Master: "Holiday_Master",
    Leave_Type: "Leave_Type",
    Employee_Leave: "Employee_Leave",
    Leave_Balance: "Leave_Balance",
    Notification: "Notification",
    Leave_Rule: "Leave_Rule",
    UserCreation_Approval: "UserCreation_Approval",
    Dashboard: "Dashboard",
    GetSignUpEmployee: "GetSignUpEmployee",
    Accessibility: "Accessibility",
    Color_Code: "Color_Code",
    InterviewStages: "InterviewStages",
    Company_Emp_Config: "Company_Emp_Config",
    Feedback: "Feedback",
    Employee_Change_Requisition:"Employee_Change_Requisition",
    EmployeeRelations: "Employee_Relations",
    Leave_Type_New :"Leave_Type_New"
    
});

// BASE_API_URL: 'http://172.16.3.142/api/', - Local system Ankit's Latest
// BASE_API_URL: 'http://172.16.0.165:8099/api/', - Local system shlesha's
// BASE_API_URL: 'http://172.16.1.204:8000/api/', - Local system Sandip's Latest

// BASE_API_URL: 'http://161.35.162.46:9094/api/', -For version1 (PROD)
// BASE_API_URL: 'http://161.35.162.46:9099/api/', -For version1 (UAT)
// BASE_API_URL: 'http://161.35.162.46:8080/api/', -For Testing (live)
// BASE_API_URL: 'http://159.65.55.237:9097/api/', -For Testing new (QA)

// BASE_API_URL: 'http://134.122.97.1:9099/api/', -For External UAT









